import { useEffect, useRef, useState } from 'react';
import './App.css';
import { getSlurps } from './firebase';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function App() {
    const [slurps, setSlurps] = useState([]);
    const [total, setTotal] = useState(0);
    const [display, setDisplay] = useState(1);
    const [color, setColor] = useState('#282c34');
    const [text, setText] = useState('Du drikker bare!');

    const limitValues = [165, 330, 495, 660, 825, 990, 1000];
    const texts = [
        'Du drikker bare!',
        'Du kan drikke meget endnu',
        'Du har drukket ca. en tredjedel af det du må',
        'Du har drukket ca. halvdelen af det du må',
        'Du har drukket ca. to tredjedele af det du må',
        'Du kan drikke en smule mere',
        'Drik ikke mere!',
    ];
    const colors = [
        '#3AB54B',
        '#8EC63F',
        '#CADB29',
        '#FEF203',
        '#FFC20F',
        '#F8931D',
        '#ED4021',
    ];

    function updateText() {
        let i = limitValues.findIndex((e) => {
            return total < e;
        });
        if (i < 0) i = 6;
        setText(texts[i]);
    }

    function updateColor() {
        let i = limitValues.findIndex((e) => {
            return total < e;
        });
        if (i < 0) i = 6;
        setColor(colors[i]);
    }

    useInterval(async () => {
        const newSlurps = await getSlurps();
        setSlurps(newSlurps);
        const newTotal = slurps.reduce((a, b) => {
            return a + b['slurp'];
        }, 0);
        setTotal(newTotal);
        updateText();
        updateColor();
    }, 2000);

    function changeDisplay() {
        let tempDis = display + 1;
        if (tempDis === 4) setDisplay(1);
        else setDisplay(tempDis);
    }

    return (
        <div>
            <div
                className={`App ${display !== 2 ? 'no-color' : ''}`}
                style={{ backgroundColor: color }}
                onClick={changeDisplay}
            >
                {display === 1 && (
                    <header className="App-header number">
                        <div style={{ width: 300, height: 300 }}>
                            <CircularProgressbar
                                value={total}
                                maxValue={1000}
                                text={`${total} ml`}
                            />
                        </div>
                    </header>
                )}
                {display === 2 && (
                    <header className="App-header color"></header>
                )}
                {display === 3 && (
                    <header className="App-header text">
                        <p>{text}</p>
                    </header>
                )}
            </div>
        </div>
    );

    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }
}

export default App;
