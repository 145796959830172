export const firebaseConfig = {
    apiKey: 'AIzaSyC88g_uW8mkZSozLfql-2QlrGK4l9GA0Vk',
    authDomain: 'kidneycoaster.firebaseapp.com',
    databaseURL:
        'https://kidneycoaster-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'kidneycoaster',
    storageBucket: 'kidneycoaster.appspot.com',
    messagingSenderId: '221967742468',
    appId: '1:221967742468:web:a7663bd879ab486ee3e751',
};
