import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { firebaseConfig } from './config';

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// Get a list of slurps from Firebase
export async function getSlurps() {
    const slurpsCol = collection(db, 'slurps');
    const slurpSnapshot = await getDocs(slurpsCol);
    const slurplist = slurpSnapshot.docs.map((doc) => doc.data());
    return slurplist;
}
